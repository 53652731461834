/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    code: "code",
    h2: "h2",
    a: "a",
    div: "div",
    strong: "strong",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Webhooks enable you to receive events from Signatu. When a certain event you are subscribed to, e.g., somebody consents to a a policy, Signatu will send a ", React.createElement(_components.code, null, "HTTP POST"), " to the registered webhook url with event data. You can use the data to trigger actions in your systems - e.g., update"), "\n", React.createElement(_components.h2, {
    id: "endpoint",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#endpoint",
    "aria-label": "endpoint permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Endpoint"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "NOTE:"), " webhooks are used across multiple Signatu APIs. Hence you will register\nwebhooks at ", React.createElement(_components.code, null, "https://api.signatu.com/webhooks/v0")), "\n", React.createElement(_components.h2, {
    id: "registering-a-webhook",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#registering-a-webhook",
    "aria-label": "registering a webhook permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Registering a webhook"), "\n", React.createElement(_components.p, null, "You can register a webhook through the API using a valid Access Token. Since an access token is associated with a specific ", React.createElement(_components.code, null, "Application"), ", the webhook will be registered for that Application."), "\n", React.createElement(_components.p, null, "To register a webhook for e.g., the ", React.createElement(_components.code, null, "consent"), " event:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "$ curl https://api.signatu.com/webhooks/v0 \\\n      -H 'Authorization: Bearer YOUR_OAUTH_TOKEN' \\\n      -H 'Content-Type: application/json' \\\n      -d  \\\n       '{\\\n          \"url\": \"https://my.server/webhookhandler\", \\\n          \"events\": [\"consent\"] \\\n       }'\n{\n  \"url\": \"https://my.server/webhookhandler\",\n  \"events\": [\n    \"consent\"\n  ],\n  \"id\": 1\n}\n")), "\n", React.createElement(_components.h2, {
    id: "custom-headers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#custom-headers",
    "aria-label": "custom headers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Custom headers"), "\n", React.createElement(_components.p, null, "You can set headers in the webhook using the ", React.createElement(_components.code, null, "headers"), " field. This can be useful to e.g., authorize the webhook access to\nyour API. To set headers, simply include a ", React.createElement(_components.code, null, "headers"), " map when registering the webhook:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "$ curl https://api.signatu.com/webhooks/v0 \\\n      -H 'Authorization: Bearer YOUR_OAUTH_TOKEN' \\\n      -H 'Content-Type: application/json' \\\n      -d  \\\n       '{\n          \"url\": \"https://my.server/webhookhandler\",\n          \"events\": [\"consent\"]\n          \"headers\": {\n            \"Authorization\": \"MY_AUTH_DETAILS\",\n            \"MyOtherHeader\": \"SOME_VALUE\"\n          }\n       }'\n{\n  \"url\": \"https://my.server/webhookhandler\",\n  \"events\": [\n    \"consent\"\n  ],\n  \"headers\": {\n    \"Authorization\": \"MY_AUTH_DETAILS\",\n    \"MyOtherHeader\": \"SOME_VALUE\"\n  }\n  \"id\": 2\n}\n")), "\n", React.createElement(_components.h2, {
    id: "showing-registered-webhooks",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#showing-registered-webhooks",
    "aria-label": "showing registered webhooks permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Showing registered webhooks"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "$ curl https://api.signatu.com/webhooks/v0 \\\n      -H 'Authorization: Bearer YOUR_OAUTH_TOKEN'\n[\n  {\n  \"url\": \"https://my.server/webhookhandler\",\n  \"events\": [\n    \"consent\"\n  ],\n  \"id\": 1\n}\n]\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
